import moment from "moment";

type DateTimeType = {
  date?: string;
  format?: string;
};
function DateTime(props: DateTimeType) {
  const { date, format = "DD MMM YYYY, hh:mm a" } = props;
  const d = date ? new Date(date) : new Date();
  const timestamp = d.getTime();
  return <>{moment(timestamp).format(format)}</>;
}

export default DateTime;
