const envApiBasePathMapping: Record<string, string> = {
  "https://dev-cdp.nw18.com": "https://api-pf-dev.nw18.com/cdp-dev/api",
  "https://stg-cdp.nw18.com": "https://api-pf-stg.nw18.com/cdp-dev/api",
  "https://beta-cdp.nw18.com": "https://api-pf.nw18.com/cdp-api/api",
  "https://cdp.nw18.com": "https://api-pf.nw18.com/cdp-api/api",
}

export const getApiBasePath = (): string => {
  console.log(process.env.NODE_ENV, window.origin);
  if (process.env.NODE_ENV !== "production") return "http://localhost:3333/api"

  if (window && window.origin) {
    return envApiBasePathMapping[window.origin]
  }
  return "https://api-pf-dev.nw18.com/cdp-dev/api"
}

export const API_URL = getApiBasePath();
