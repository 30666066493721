import React from "react";
import ListLoader from "src/Modules/LayoutModule/Components/ListLoader/ListLoader";
import useTenantsQuery from "../../Hooks/useTenantsQuery";
import useTenantStatusMutation from "../../Hooks/useTenantStatusMutation";
import DateTime from "src/Components/DateTime/DateTime";

function TagList() {
  const {tenants = [], refetch} = useTenantsQuery({})
  const {mutate: changeStatus} = useTenantStatusMutation();
  return (
    <div className="container-fluid p-0 px-lg-0 px-md-0">
      <div className="container-fluid px-lg-4">
        <div className="row">
          <div className="col-md-12 mt-4">
            <div className="card">
              <div className="card-body">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="d-sm-flex align-items-center" style={{flex: 0.5}}>
                    <h2 className="h3 mb-3 mb-sm-0 semibold_font fz18">
                      Tenants List
                    </h2>
                  </div>
                </div>
              </div>
              <div id="main" className="mt-0 mb-3">
                <div className="p-3">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Tenant Id</th>
                          <th>Tenant Name</th>
                          <th>Status</th>
                          <th>Updated At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tenants.map((tenant: any) => (
                          <tr>
                            <td className="border_bottom">
                              {tenant.id}
                            </td>
                            <td className="border_bottom">
                              {tenant.name}
                            </td>
                            <td className="border_bottom">
                              <div className="d-flex align-items-center">
                                <div className="custom_switch mx-4">
                                  <label
                                    className={`switch ${tenant.is_active ? "active" : "inactive"
                                      } mb-0`}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={tenant.is_active}
                                      onChange={() => {
                                        changeStatus({
                                          tenantId: tenant.id,
                                          is_active: !tenant.is_active
                                        }, {
                                          onSuccess: () => refetch()
                                        })
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </td>
                            <td className="border_bottom">
                              <DateTime  date={tenant.updated_at} />
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={4}>
                            <ListLoader
                              loading={false}
                              dataLength={
                                (tenants).length
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TagList;
