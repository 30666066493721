import React from 'react';
import useSetHeaderOptions from 'src/Modules/LayoutModule/Hooks/useSetHeaderOptions';
import TenantList from 'src/Modules/TagModule/Components/TenantList/TenantList';

const TenantsPage = () => {
  useSetHeaderOptions({title: "Tenants List"});

  return <TenantList />;
}
export default TenantsPage;
