import {fetcher, jsonToFormData} from "src/Utils/Helpers";
import queryString from "query-string";

class TagService {
  queryKeys = {
    tags: (tenantId: string) => `${tenantId}-tags`,
    tenants: "all-tenants",
  };
  getTags = (params: any) => {
    const qs = queryString.stringify(params);
    return fetcher({
      url: `/tags?${qs}`,
    });
  };
  getTanants = (params?: any) => {
    const qs = queryString.stringify(params);
    return fetcher({
      url: `/tenants?${qs}`,
    });
  };
  addTag = (data: any) => {
    return fetcher({
      url: "/tags",
      data: jsonToFormData(data),
      method: "POST",
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
  editTag = (data: any) => {
    return fetcher({
      url: "/tags",
      data: jsonToFormData(data),
      method: "PUT",
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
  deleteTag = (data: any) => {
    return fetcher({
      url: "/tags",
      data,
      method: "DELETE",
    });
  };
  tagStatus = (data: any) => {
    return fetcher({
      url: "/tag-status",
      data,
      method: "POST"
    });
  };
  tenantStatus = (data: any) => {
    return fetcher({
      url: "/tenant-status",
      data,
      method: "POST"
    });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TagService();
