import { BrowserRouter, Switch } from "react-router-dom";
import LoginPage from "src/Pages/login/LoginPage";
import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";
import TagManagerAdmin from "src/Pages/tags/TagsPage";
import TenantsPage from "src/Pages/tenants/TenantsPage";

function AppRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute path={"/"} exact>
          <LoginPage />
        </AuthRoute>
        <AuthRoute path={"/login"} exact>
          <LoginPage />
        </AuthRoute>
        <AuthRoute path={"/health"} exact>
          <div className="text-center mt-5 pt-5">CDP Admin is healthy</div>
        </AuthRoute>
        <ProtectedRoute path={"/tags"} exact>
          <TagManagerAdmin />
        </ProtectedRoute>
        <ProtectedRoute path={"/tenants"} exact>
          <TenantsPage />
        </ProtectedRoute>
      </Switch>
    </BrowserRouter>
  );
}

export default AppRouter;
